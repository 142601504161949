let resumeData = {
    "imagebaseurl":"https://github.com/gitNitroTitan",
    "name": "Jeremiah King",
    "role": "Fullstack Web Developer",
    "linkedinId":" https://www.linkedin.com/in/jeremiahkingmbadev/",
    "roleDescription": " I enjoy working with back end languages such as Python and .NET, as well as front end technologies like React and Next JS. I feel that I have a solid work ethic, and I believe in working hard to create a solution.",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/jeremiahkingmbadev/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/gitNitroTitan?tab=repositories",
          "className":"fa fa-github"
        },
      ],
    "aboutme":"Currently, I am completing a software engineer technical residency and working on a personal project in Python/ Django with a React client. I am an MBA graduate with 21 years of real-world healthcare leadership and technical experience in many situations. ",
    "address":"Tennessee, USA",
    "website":"https://github.com/gitNitroTitan",
    "education":[
      {
        "UniversityName":"Nashville Software School",
        "specialization":"Full Stack Web Development",
        "MonthOfPassing":"March",
        "YearOfPassing":"2023",
        "Achievements":"Completion of Certificate"
      },
      {
        "UniversityName":"Western Governors University",
        "specialization":"Masters in Business Administration",
        "MonthOfPassing":"July",
        "YearOfPassing":"2021",
        "Achievements":"MBA"
      },
      {
        "UniversityName":"University of West Florida",
        "specialization":"Interdisciplinary Information Technology",
        "Achievements":"Web Development Certification"
      },
      {
        "UniversityName":"University of South Alabama",
        "specialization":"Bachelors of Radiologic Sciences",
        "MonthOfPassing":"July",
        "YearOfPassing":"2004",
        "Achievements":"Certifications in Xray, MRI, and CT"
      },
    ],
    "work":[
      {
        "CompanyName":"HCA",
        "specialization":"Software Engineering Technical Resident",
        "MonthOfLeaving":"",
        "YearOfLeaving":"",
        "Achievements":"Some Achievements"
      },
      {
        "CompanyName":"Premier Radiology/ Radiology Partners",
        "specialization":"Cardiac MRI technologist, Clinic supervisor",
        "MonthOfLeaving":"September",
        "YearOfLeaving":"2022",
        "Achievements":"Created a solid program for safer electronic device scanning in an MRI environment"
      },
      {
        "CompanyName":"Vanderbilt Medical Center",
        "specialization":"Evening Lead MRI technologist",
        "MonthOfLeaving":"September",
        "YearOfLeaving":"2014",
        "Achievements":"Led a shift team of 6 with an anesthesia team to coordinate, screen, sedate, and perform pediatric MRI scans"
      }
    ],
    "skillsDescription":"Language Exposure",
    "skills":[
      {
        "skillname":"React JS"
      },
      {
        "skillname":"Next JS"
      },
      {
        "skillname":"Python"
      },
      {
        "skillname":"Django"
      },
      {
        "skillname":"ASP.NET Core"
      },
      {
        "skillname":"C#"
      }
    ],
    // "portfolio":[
    //   {
    //     "name":"project1",
    //     "description":"mobileapp",
    //     "imgurl":"images/portfolio/phone.jpg"
    //   },
    //   {
    //     "name":"project2",
    //     "description":"mobileapp",
    //     "imgurl":"images/portfolio/project.jpg"
    //   },
    //   {
    //     "name":"project3",
    //     "description":"mobileapp",
    //     "imgurl":"images/portfolio/project2.png"
    //   },
    //   {
    //     "name":"project4",
    //     "description":"mobileapp",
    //     "imgurl":"images/portfolio/phone.jpg"
    //   }
    // ],
    // "testimonials":[
    //   {
    //     "description":"This is a sample testimonial",
    //     "name":"Some technical guy"
    //   },
    //   {
    //     "description":"This is a sample testimonial",
    //     "name":"Some technical guy"
    //   }
    // ]
  }
  
  export default resumeData
